/* Container */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(13deg, #75a7e9, #040967);
}

/* Card container */
.card {
  background-color: #ecf0f0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

/* Title style */
.card h1 {
  font-size: 24px;
  color: #000000;
  margin-bottom: 15px;
}

/* Input and button styles */
.input-container {
  margin-bottom: 15px;
  text-align: left;
}

.input-container label {
  display: block;
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #000306;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0f79e3;
}

.result {
  font-size: 18px;
  color: #000000;
}

.guidance {
  font-size: 10px; /* Smaller font size for the guidance */
  color: #777;
}

.clear-button {
  background-color: #000306;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clear-button:hover {
  background-color: #0f79e3;
}

.button-container {
  display: flex;
  justify-content: space-between;
}
